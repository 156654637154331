import React, {Component} from 'react';
import Priv1 from '../images/privacy1.jpg';
import Priv2 from '../images/privacy2.jpg';
import Priv3 from '../images/privacy3.jpg';

class Privacy extends Component {
    render(){
        return(
            <div className="m-t-4 pagetransition">
                <div className="card-columns">
                        <div className="card" >
                            <img className="card-img-top" src={Priv1} alt="1"/>
                        </div>
                        <div className="card d-none d-md-block" >
                            <img className="card-img-top" src={Priv2} alt=""/>
                        </div>
                        <div className="card" >
                            <img className="card-img-top" src={Priv3} alt=""/>
                        </div>
                </div>
                
                <h2>Privacy Policy</h2>
                <p><strong>What information do we gather?</strong>
                Information on cypressfund.com is gathered in two ways: (1) indirectly 
                    (for example, through our site's technology); and (2) directly (for example,
                     when you provide information on various pages).</p>
                <p>We collect information directly from you in a number of ways, some of which 
                    we describe in this Privacy Statement. One way is through the use of cookies. 
                    Cookies are small files of information which save and retrieve information 
                    about your visit to cypressfund.com—for example, how you entered our site, 
                    how you navigated through the site, and what information was of interest 
                    to you. The cookies we use identify you merely as a number. (If you are 
                    uncomfortable regarding cookies use, please keep in mind you can disable 
                    cookies on your computer by changing the settings in preferences or 
                    options menu in your browser.)</p>
                <p>We also collect information when you voluntarily submit it to us. When we collect this type of 
                    information, we will notify you as to why we are asking for information and how this 
                    information will be used. It is completely up to you whether or not you want to provide it.</p>
                <p><strong>Will we share this with outside parties?</strong> We will not sell individual information 
                    and will share it only with our advisors. Before you submit any information, 
                    we will notify you as to why we are asking for specific information and it is 
                    completely up to you whether or not you want to provide it.</p>
                <p><strong>What about sensitive personal data?</strong> We do not generally seek to collect sensitive 
                    personal data through this site. If we do seek to collect such data, we will ask 
                    you to consent to our proposed uses of the data. </p>
                <p><strong>What about data security? </strong>
                    We take appropriate steps to maintain the security of your data. You should 
                    understand that the open nature of the Internet is such that data may flow over 
                    networks without security measures and may be accessed and used by people other 
                    than those for whom the data is intended.</p>
                <p>We are committed to maintaining your privacy. If you have any questions, please contact us. </p>
                <h2>Terms of Use</h2>
                <p>Your access to and use of this site is subject to the following terms and conditions and all applicable laws. By accessing and using this site, you accept the following terms and conditions, without limitation or qualification.
</p>
                <p>Unless otherwise stated, the contents of this site including, but not limited to, the text and images contained herein and their arrangement are the property of Cypress Realty Holdings Company. All trademarks used or referred to in this website are the property of their respective owners.
</p>
                <p>Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark or other proprietary interest of Cypress Realty Holdings Company or any third party. This site and the content provided in this site, including, but not limited to, graphic images, audio, video, html code, buttons, and text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, without the prior written consent of Cypress Realty Holdings Company, except that you may download, display, and print one copy of the materials on any single computer solely for your personal, non-commercial use, provided that you do not modify the material in any way and you keep intact all copyright, trademark, and other proprietary notices.
</p>
                <p>The information provided on this site is free of charge and for informational purposes only and does not create a business or professional services relationship between you and Cypress Realty Holdings Company. Links on this site may lead to services or sites not operated by Cypress Realty Holdings Company. No judgment or warranty is made with respect to such other services or sites and Cypress Realty Holdings Company takes no responsibility for such other sites or services. A link to another site or service is not an endorsement of that site or service. Any use you make of the information provided on this site, or any site or service linked to by this site, is at your own risk.
</p>
                <p>This site and its contents are provided "as is" and Cypress Realty Holdings Company makes no representation or warranty of any kind with respect to this site or any site or service accessible through this site. Cypress Realty Holdings Company expressly disclaims all express and implied warranties including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In no event will Cypress Realty Holdings Company be liable to any party for any direct, indirect, incidental, special, exemplary, consequential, or other damages (including, but not limited to, lost profits, business interruption, loss of programs or data) without regard to the form of action and whether in contract, tort, negligence, strict liability, or otherwise, arising out of or in connection with this site, any content on or accessed through this site or any site service linked to, or any copying, displaying, or use thereof.
                </p>
                <p>Cypress Realty Holdings Company maintains this site in Tennessee, U.S.A. and you agree that these terms of use and any legal action or proceeding relating to this site shall be governed by the laws of the State of Tennessee without reference to its choice of law rules. If you attempt to bring any legal proceedings against Cypress Realty Holdings Company you specifically acknowledge that Cypress Realty Holdings Company is free to choose the jurisdiction of our preference as to where such action against us may be held. As you have agreed by using this site to choose the laws of the State of Tennessee to govern any such proceedings, we will probably choose to defend any such action in Tennessee and we can make this decision entirely as it suits us, without regard to where in the world you are located, or from where in the world you visited this site.
                </p>
                <p>You are responsible for complying with the laws of the jurisdiction from which you are accessing this site and you agree that you will not access or use the information on this site in violation of such laws. Unless expressly stated otherwise herein, any information submitted by you through this site shall be deemed non-confidential and non-proprietary. You represent that you have the lawful right to submit such information and agree that you will not submit any information unless you are legally entitled to do so. Because of the open nature of the Internet, we recommend that you not submit information you consider confidential.
                </p>
                <p>Cypress Realty Holdings Company does not accept unauthorized idea submissions outside of established business relationships. To protect the interests of our current clients and ourselves, we must treat the issue of such submissions with great care. Importantly, without a clear business relationship, Cypress Realty Holdings Company cannot and does not treat any such submissions in confidence. Accordingly, please do not communicate unauthorized idea submissions to Cypress Realty Holdings Company through this website. Any ideas disclosed to Cypress Realty Holdings Company outside a pre-existing and documented confidential business relationship are not confidential and Cypress Realty Holdings Company may therefore develop, use and freely disclose or publish similar ideas without compensating you or accounting to you. Cypress Realty Holdings Company will make every reasonable effort to return or destroy any unauthorized idea submissions without detailed review of them. However, if a review is necessary in Cypress Realty Holdings Company's sole discretion, it will be with the understanding that Cypress Realty Holdings Company assumes no obligation to protect the confidentiality of your idea or compensate you for its disclosure or use. By submitting an idea or other detailed submission to Cypress Realty Holdings Company through this website, you agree to be bound by the terms of this stated policy.
                </p>








            </div>
        )
    }
}

export default Privacy