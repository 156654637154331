import React, {Component} from 'react';
import {NavLink} from "react-router-dom";


class Managers extends Component {
    render() {
        return (
            <div className="pagetransition m-t-6" id="Committee">   
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <NavLink to="/price-ford" >
                            <div className="card">
                                <img src="/images/PriceFordNew.jpg" className="card-img-top" alt="Price Ford"/>
                                <div className="card-body">
                                <h5 className="m-b-0 text-center card-title">Price D. Ford</h5>
                                <h6 className="m-b-0 text-center">SIOR, CCIM</h6>
                                </div>
                            </div> 
                        </NavLink>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6">
                        <p className="pf-m-t-10">
                        The Fund Manager, as well as a co-investor in the fund is Price D. Ford, SIOR, CCIM. Price has 25 years of experience in acquiring, developing, leasing, managing, financing and disposing of commercial real estate assets in the Southeastern United States.
                        </p>
                    </div>
                </div>
        
            
                <div className="row">
                    <div className="col-xl-5 col-lg-3"></div>
                    <div className="col-xl-4 col-lg-5 col-md-6 order-xl-1 order-lg-1 order-md-1 order-sm-2 order-2">
                        <p className="">
                        Mr. Jarratt is a Principal of Cypress Realty Holdings Company as well as a Co-Manager and co-investor of the fund. Mr. Jarratt also serves as a Partner and Principal in Ford Jarratt Realty &amp; Development Company.   
                        </p>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1">
                        <NavLink to="/joe-jarratt" >
                            <div className="card m-t-15">
                                <img src="/images/JoeJarret.jpg" className="card-img-top" alt="Joe Jarratt"/>
                                <div className="card-body">
                                    <h5 className="m-b-0 text-center card-title">Joseph W. Jarratt</h5>
                                    <h6 className="m-b-0 text-center">SIOR, CCIM</h6>
                                </div>
                            </div> 
                        </NavLink>
                    </div>
                </div>
                    
            </div>
        )
    }
}

export default Managers