import React, {Component} from 'react'
import HomeTestSlideshow from './Slideshow/HomeTestSlideshow'


class HomeTest extends Component {
    constructor(){
        super()
        this.state = {}
    }


    render(){
        return(
            <div className="pagetransition">
               
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                            
                                <HomeTestSlideshow groupname="topleft" wait={0} /> 
                            
                    </div>
                    <div className="col-sm-12 col-md-4">
                            
                                <HomeTestSlideshow groupname="topcenter" wait={4000} /> 
                                           
                    </div>
                    <div className="col-sm-12 col-md-4">
                            
                                <HomeTestSlideshow groupname="topright" wait={2000} />
                            
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                            
                                <HomeTestSlideshow groupname="bottomleft" wait={10000} /> 
                            
                    </div>
                    <div className="col-sm-12 col-md-4">
                            
                                <HomeTestSlideshow groupname="bottomcenter" wait={6000} />  
                            
                    </div>
                    <div className="col-sm-12 col-md-4">
                            
                                <HomeTestSlideshow groupname="bottomright" wait={8000} /> 
                            
                    </div>
                </div>
                <div className="row m-t-4">
                    <div className="col">
                        <h5>
                        Cypress Realty Holdings Company was formed for the purpose of creating a professionally managed, geographically diverse real estate portfolio that provides its investment partners with high current income, certain tax advantages and long-term capital appreciation for its members by purchasing and/or developing primarily investment grade commercial real estate in the southeastern United States.  
                        </h5>
                    </div>
                </div>
                
            
            </div>
        )
    }
}

export default HomeTest