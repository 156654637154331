import React from 'react';
import {
  NavLink
} from "react-router-dom";



class CFFooter extends React.Component{
    render(){
        return (
            <footer>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-lg-10 col-xl-8">
                    <p className="upperright"><NavLink to="/privacy">Privacy Policy</NavLink></p>
                    <div className="m-t-22 d-block d-md-none">
                      <p>700 Colonial Road, Suite 100<br/>Memphis, TN 38117</p>
                      <p>Phone: (901) 682-7606<br/>Fax: (901) 682-7992</p>
                    </div>
                  </div>
                </div>
              </div> 
            </footer>
        )
    }
}

export default CFFooter