import React, {Component} from 'react';
import Baptist from '../images/BaptistMedicalGroup.jpg';
import Poplar from '../images/Poplarcollection.jpg';
import Wolf from '../images/about3.jpg';
import ContactForm from './ContactForm';

class About extends Component {
    render(){
        return(
            <div className="m-t-4 pagetransition">
                <div className="card-columns">
                <div className="card" >
                        <img className="card-img-top" src={Wolf} alt="Wolf"/>
                    </div>
                    <div className="card" >
                        <img className="card-img-top" src={Poplar} alt="Poplar Collection"/>
                    </div>
                    <div className="card d-none d-md-block" >
                        <img className="card-img-top" src={Baptist} alt="Baptist Medical Group"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-8">
                    <h2>ABOUT US</h2>
                    <p>Cypress Realty Holdings Company, LLC was established in June 2002, 
                        as a board managed, Delaware Limited Liability Company pursuant to the 
                        Delaware Limited Liability Company Act.</p>

                    <p>The company was formed for the purpose of creating a professionally managed, 
                        geographically diverse real estate portfolio that provides its investment 
                        partners with high current income, certain tax advantages and long term 
                        capital appreciation for its members by purchasing and/or developing 
                        primarily investment grade commercial real estate in the southeastern 
                        United States.</p>

                    <p>The company intends to aggressively seek to add value to the properties it 
                        acquires by competitively bidding and securing primarily non-recourse debt 
                        in an effort to maximize yields through prudent, positive leverage. 
                        Given the historically low interest rate environment, the company believes 
                        it can substantially increase yields, further enhanced by securing long term, 
                        non-recourse debt, reducing operating expenses through multi-investment property 
                        cost efficiencies and competitively bidding, negotiating and securing 
                        professionally management with qualified professional management companies.</p>

                    <p>The fund seeks to invest its capital in investment grade commercial real estate 
                        through the acquisition or development of properties consisting of a geographic 
                        diversification of primarily net leased, retail shopping centers and industrial 
                        projects. The company seeks A+ to C+ assets of an aggregate dollar value of 
                        $25 million or less per property which will allow the company to spread its 
                        capital and diversify risk to as many projects as possible. The company seeks 
                        to target properties of this relative size because it believes it reduces the 
                        company's competition by excluding many institutional owners such as REIT's, 
                        pension funds and insurance companies whose return parameters are much lower 
                        than the company's and intends to be all cash purchasers.</p>
                    </div>
                    <div className="col-xl-4">
                        <h2>CONTACT US</h2>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        )
    }
}

export default About