import React from 'react';

class JoeJarratt extends React.Component {
    render() {
        return(
            <div>
                <div className="row m-t-8">
                    <div className="col-md-3">
                        <div className="card m-b-4" id="IndPerson">
                            <img src="/images/JoeJarret.jpg" alt="Joe Jarratt" className="card-img-top"/> 
                            <div className="card-body">
                                <h5 className="m-b-0 text-center card-title">Joseph W. Jarratt</h5>
                                <h6 className="m-b-0 text-center">SIOR, CCIM</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <p className="pf-m-t-10">Mr. Jarratt is a Principal of Cypress Realty Holdings Company as well as a Co-Manager and co-investor of the fund. Mr. Jarratt also serves as a Partner and Principal in Ford Jarratt Realty &amp; Development Company.</p>
                        <p>From June 1996 to March 2007, Mr. Jarratt was a member of Boyle Investment Company’s Commercial Sales and Leasing Department offering a wide range of services including development, sales and leasing, property management, real estate finance, site selection, and construction management. Mr. Jarratt represented some of the key land owners and investors in the Mid-South.</p>
                        <p>Mr. Jarratt earned the SIOR designation from the Society of Industrial and Office Realtors and the CCIM designation from the Commercial Investment Real Estate Institute. He is a member of the Memphis Area Association of Realtors, Tennessee Association of Realtors, the National Association of Realtors, and the Lambda Alpha Real Estate Fraternity. Mr. Jarratt has a Principal Broker’s license in Arkansas, Mississippi and Tennessee.</p>
                        <p>Mr. Jarratt attended the University of Arkansas at Fayetteville where he earned a B.A. Degree in Real Estate and Finance. Mr. Jarratt serves on the Board of Directors of First National Bank of Eastern Arkansas and previously served on the Board of the Wolf River Conservancy. </p>
                    </div>
                </div>

            </div>
            )
    }
}

export default JoeJarratt