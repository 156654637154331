import React from 'react';
import logo from '../adminlogo3.png';
import TodaysDate from './TodaysDate';

function CFHeader (props){  
    
        return(         
            <header className="App-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-10">
                    <img src={logo} className="App-logo img-fluid" alt="Cypress Realty Holdings Company" />
                  </div>
                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-2 col-2">
                    <TodaysDate/> 

                    <nav id="Hamburger" className="navbar navbar-dark d-block d-md-none">
                      <button type="button" className="navbar-toggler" onClick={props.handleClick}><span className="navbar-toggler-icon" ></span></button>
                    </nav>
                    
                  </div>
                  <div className="col-xl-4 col-lg-2"></div>
                </div>
              </div>
            </header>
        )
    
}

export default CFHeader