import React from 'react'
import axios from 'axios'

class ContactForm extends React.Component {
    constructor(props){
        super()
        this.state = {
            name:"",
            email: "",
            comments: "",
            mailSent:false,
            error:null
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name
        this.setState({
            [name]: value
        })
    }

    handleSubmit(event){
        //console.log(this.state)
        axios.get('http://www.cypressfund.com/submitform.cfm',{
            headers: {'Access-Control-Allow-Origin': '*'},
            params: {name: this.state.name, email: this.state.email, comments: this.state.comments}
        })
        .then(result => {
            this.setState({mailSent:true})
            alert("Thank you for contacting us.")
            //console.log(result)
        })
        .catch(error => this.setState({error:error.message}) 
        )
            
        event.preventDefault();
        this.setState (
            {name:"",
            email: "",
            comments: ""
            })
    }

    render(){
        return(
             <form onSubmit={this.handleSubmit}>
            
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" name="name" value={this.state.name} onChange={this.handleChange} className="form-control" required="required"/>
                </div>
                <div className="form-group">
                    <label >Email</label>
                    <input type="text" name="email" value={this.state.email} onChange={this.handleChange} className="form-control" required="required"/>
                </div>
                <div className="form-group">
                    <label >Comments</label>
                    <textarea name="comments" value={this.state.comments} onChange={this.handleChange} className="form-control" required="required" rows="5"></textarea>
                </div>
                <button type="submit" className="btn btn-light float-right">Submit</button>
            
             </form>
        )
    }
}

export default ContactForm