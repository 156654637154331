import React from 'react'
import './slides.css'

function SlideTest(props) {
    const slides = props.slides;
    const [curr, setCurr] = React.useState(0);
    const length = slides.length;

    const goToNext = () => {
        setCurr(curr === length - 1 ? 0 : curr + 1);
    }
 
 React.useEffect( () => {
    setTimeout(goToNext, 12000);
    })

if(!Array.isArray(slides) || length <= 0){
    return null;
}

    
        return(
            <section className="slider">
            {slides.map((s,i) => (
                <div className={i === curr ? "slide active " + s.groupname : "slide " + s.groupname} key={s.ordernum}>
                {i === curr && (
                    <img src={s.url} alt="Cypress Fund Property" className="image" />)}
                
                </div>
            ))}
            </section>
        )
    
}

export default SlideTest