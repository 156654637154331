import React, {
  Component
} from 'react';
import {
  BrowserRouter,
  Switch,
  Route, 
  NavLink
} from "react-router-dom";
import {
  CSSTransition, 
  TransitionGroup 
} from 'react-transition-group';
import './App.css';
import './components/Committee/committee.css';
import CFHeader from './components/CFHeader';
import CFFooter from './components/CFFooter';
import Home from './components/Home';
import HomeTest from './components/HomeTest';
import Properties from './components/Properties/Properties';
import Managers from './components/Managers';
import PriceFord from './components/PriceFord';
import JoeJarratt from './components/JoeJarratt';
// import Committee from './components/Committee/Committee';
import Development from './components/Development';
import About from './components/About';
import Privacy from './components/Privacy';
import FordJarrattRealty from './components/FordJarrattRealty';
// import WalterAllen from './components/Committee/WalterAllen';



class App extends Component {
  constructor(){
    super()
    this.state = {
      collapsed: "closed"
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(prevState){
    this.setState(prevState => prevState.collapsed === "open" ? {collapsed: "closed"} : {collapsed: "open"} )
  }

  render(){
    
    return (
      <div className="App">
        <BrowserRouter>
          <CFHeader handleClick={this.handleClick} />
        
        <div className="bodybkg">
          <div className="container-fluid">
            <div className="row">

              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
              
                  <nav id="Menu" className={"nav flex-column nav-pills d-md-block " + this.state.collapsed} >
                    <NavLink className="nav-link" exact to="/">Home</NavLink>
                    <NavLink className="nav-link" to="/properties">Properties</NavLink>
                    <NavLink className="nav-link" to="/managers" >Fund Managers</NavLink>
                    <NavLink className="nav-link" to="/Ford-Jarratt-Realty">Ford Jarratt Realty &amp; Development Company</NavLink>
                    {/* <NavLink className="nav-link" to="/committee">Investment Committee</NavLink> */}
                    <NavLink className="nav-link" to="/development">Development &amp; Acquisition Parameters</NavLink>
                    <NavLink className="nav-link" to="/about" >About Us</NavLink>
                    <a className="nav-link" href="http://www.cypressfund.com/newadmin/financials.cfm" target="_blank" rel="noopener noreferrer">Members Login</a>
                  </nav>
               
                
                <div className="d-none d-md-block">
                  <p>700 Colonial Road, Suite 100<br/>Memphis, TN 38117</p>
                  <p>Phone: (901) 682-7606<br/>Fax: (901) 682-7992</p>
                </div>
              </div>
             
              <div className="col-xl-6 col-lg-7 col-md-8 col-sm-12">
                <Route render={({location}) => (
                  <TransitionGroup>
                    <CSSTransition key={location.key} timeout={800} classNames="fade">
                      <Switch location={location}>
                        <Route exact path="/" component={HomeTest}/>
                        <Route exact path="/hometest" component={Home}/>
                        <Route path="/properties" component={Properties}/>
                        <Route path="/managers" component={Managers}/>
                        <Route path="/price-ford" component={PriceFord}/>
                        <Route path="/joe-jarratt" component={JoeJarratt}/>
                        <Route path="/Ford-Jarratt-Realty" component={FordJarrattRealty} />
                        {/* <Route path="/committee" component={Committee}/> */}
                        <Route path="/about" component={About}/>
                        <Route path="/development" component={Development}/> 
                        <Route path="/privacy" component={Privacy}/>
                        {/* <Route path="/walter-allen" render={(props) => <WalterAllen id="1"/>}/>
                        <Route path="/james-lackie" render={(props) => <WalterAllen id="3"/>}/>
                        <Route path="/jefferies-mann" render={(props) => <WalterAllen id="5"/>}/>
                        <Route path="/kent-wunderlich" render={(props) => <WalterAllen id="2"/>}/>
                        <Route path="/reid-sanders" render={(props) => <WalterAllen id="4"/>}/>
                        <Route path="/laurence-bloch" render={(props) => <WalterAllen id="6"/>}/>  */}
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                )} />
                
              </div>
            </div>
            
          </div>
        </div>
        
          <CFFooter/>
        </BrowserRouter>
      </div>
      
    );
  }
 
}

export default App;
