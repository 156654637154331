import React from 'react';

function TodaysDate(){
    const todaysdate = new Date();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
    const m = months[todaysdate.getMonth()];
    const d = todaysdate.getDate();
    const y = todaysdate.getFullYear();
    const newdate = m + " " + d + ", " + y;
    return (
        <span className="lowerright d-none d-md-block">{newdate}</span>
    )
}

export default TodaysDate;