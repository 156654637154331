import React from 'react'
import FordJarrattLogo from '../images/FordJarrattLogo.jpg'

function FordJarrattRealty(){
    return(
        <div>
            <div className="row pf-m-t-6">
                <div className="col">
                    <img src={FordJarrattLogo} alt="Ford Jarratt Realty" className="img-fluid fjlogo" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>Ford Jarratt Realty &amp; Development Company was formed in 2007 by Price Ford and Joe Jarratt.</p>
                    <p>Ford Jarratt provides brokerage, leasing and development services for Cypress Realty Holdings Company as well as third party partners and clients.</p>
                    
                </div>
            </div>
            <div className="row">
            <div className="col-md-6">
                        <p><strong>Price D. Ford, SIOR, CCIM</strong><br/>
                        
                        700 Colonial Road, Suite 100<br/>
                        Memphis, TN 38117<br/>
                        Phone: (901) 682-7606<br/>
                        Fax: (901) 682-7992<br/>
                        Email: <a href="mailto:pford@cypressfund.com" target="_blank" rel="noopener noreferrer">pford@cypressfund.com</a></p>
                </div>
                <div className="col-md-6">
                        <p><strong>Joseph W. Jarratt, SIOR, CCIM</strong><br/>
                        700 Colonial Road, Suite 100<br/>
                        Memphis, TN 38117<br/>
                        Phone: (901) 682-7606<br/>
                        Fax: (901) 682-7992<br/>
                        Email: <a href="mailto:joej@cypressfund.com" target="_blank" rel="noopener noreferrer">joej@cypressfund.com</a></p>
                </div>
                
            </div>
                        
        </div>

    )
}

export default FordJarrattRealty