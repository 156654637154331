const SlideshowImages = [
    {
        ordernum: 1,
        groupname: "topleft",
        url:"/images/topleft/1.jpg",
        altname: ""
    },
    // {
    //     ordernum: 2,
    //     groupname: "topleft",
    //     url:"/images/topleft/2.jpg",
    //     altname: ""
    // },
    {
        ordernum: 3,
        groupname: "topleft",
        url:"/images/topleft/3.jpg",
        altname: ""
    },
    {
        ordernum: 4,
        groupname: "topleft",
        url:"/images/topleft/4.jpg",
        altname: ""
    },
    {
        ordernum: 5,
        groupname: "topleft",
        url:"/images/topleft/5.jpg",
        altname: ""
    },
    {
        ordernum: 6,
        groupname: "topcenter",
        url:"/images/topcenter/1.jpg",
        altname: ""
    },
    {
        ordernum: 8,
        groupname: "topcenter",
        url:"/images/topcenter/2.jpg",
        altname: ""
    },
    {
        ordernum: 9,
        groupname: "topcenter",
        url:"/images/topcenter/3.jpg",
        altname: ""
    },
    // {
    //     ordernum: 10,
    //     groupname: "topcenter",
    //     url:"/images/topcenter/4.jpg",
    //     altname: "Macaroni Grill"
    // },
    {
        ordernum: 13,
        groupname: "topright",
        url:"/images/topright/1.jpg",
        altname: ""
    },
    {
        ordernum: 14,
        groupname: "topright",
        url:"/images/topright/2.jpg",
        altname: ""
    },
    {
        ordernum: 15,
        groupname: "topright",
        url:"/images/topright/3.jpg",
        altname: ""
    },
    {
        ordernum: 16,
        groupname: "topright",
        url:"/images/topright/4.jpg",
        altname: ""
    },
    {
        ordernum: 17,
        groupname: "topright",
        url:"/images/topright/5.jpg",
        altname: ""
    },
    // {
    //     ordernum: 19,
    //     groupname: "bottomleft",
    //     url:"/images/bottomleft/1.jpg",
    //     altname: "Edwin Watts"
    // },
    {
        ordernum: 20,
        groupname: "bottomleft",
        url:"/images/bottomleft/2.jpg",
        altname: ""
    },
    {
        ordernum: 21,
        groupname: "bottomleft",
        url:"/images/bottomleft/3.jpg",
        altname: ""
    },
    {
        ordernum: 22,
        groupname: "bottomleft",
        url:"/images/bottomleft/4.jpg",
        altname: ""
    },
    {
        ordernum: 25,
        groupname: "bottomcenter",
        url:"/images/bottomcenter/1.jpg",
        altname: ""
    },
    // {
    //     ordernum: 26,
    //     groupname: "bottomcenter",
    //     url:"/images/bottomcenter/2.jpg",
    //     altname: "Laurelwood Collection Center"
    // },
    {
        ordernum: 27,
        groupname: "bottomcenter",
        url:"/images/bottomcenter/3.jpg",
        altname: ""
    },
    {
        ordernum: 28,
        groupname: "bottomcenter",
        url:"/images/bottomcenter/4.jpg",
        altname: ""
    },
    {
        ordernum: 29,
        groupname: "bottomcenter",
        url:"/images/bottomcenter/StageCollectionShoppingCenter.jpg",
        altname: "Stage Collection Shopping Center"
    },
    {
        ordernum: 30,
        groupname: "bottomcenter",
        url:"/images/bottomcenter/6.jpg",
        altname: ""
    },
    {
        ordernum: 31,
        groupname: "bottomright",
        url:"/images/bottomright/1.jpg",
        altname: ""
    },
    {
        ordernum: 32,
        groupname: "bottomright",
        url:"/images/bottomright/chilis4.jpg",
        altname: "Chili's"
    },
    // {
    //     ordernum: 33,
    //     groupname: "bottomright",
    //     url:"/images/bottomright/3.jpg",
    //     altname: "Parkway Place"
    // },
    {
        ordernum: 34,
        groupname: "bottomright",
        url:"/images/bottomright/4.jpg",
        altname: ""
    },
    {
        ordernum: 35,
        groupname: "bottomright",
        url:"/images/bottomright/5.jpg",
        altname: ""
    }
]

export default SlideshowImages