import React from 'react'
import SlideTest from './SlideTest'
import './slides.css'
import {CSSTransition} from 'react-transition-group'

class HomeTestSlideshow extends React.Component {
    constructor(){
        super()
        this.state = {
            slideshow: [],
            hidden: true,
            inProp: false,
            setInProp: false
        }
        
    }

    componentDidMount(){
        fetch('/services/newpropertyImages.cfc?method=getHome&category=' + this.props.groupname)
        .then(response => response.json())
        .then(data => {
            const newdata = data.map(function(p) {
                return {id: p.ID, url: p.URL, ordernum: p.ORDERNUM, groupname:p.GROUPNAME, altname: p.ALTNAME }
            }) 
            this.setState({slideshow: newdata})
            setTimeout(function(){
                this.setState({hidden: false, setInProp:true})
            }.bind(this), this.props.wait)
        })
         
    }

   
    render(){
        
        return(
            <div className="slidecontainer">
                 {this.state.hidden ? null : <CSSTransition in={this.state.inProp} timeout={200} classNames="my-node"><SlideTest key={this.state.slideshow.ordernum} slides={this.state.slideshow} ></SlideTest></CSSTransition>} 
                {/* <SlideTest key={this.state.slideshow.ordernum} slides={this.state.slideshow} wait={this.props.wait} ></SlideTest> */}
            </div>
        )
    }

}

export default HomeTestSlideshow