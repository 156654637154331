import React, {Component} from 'react'
import ImageGallery from 'react-image-gallery'
//import PropertyImages from './propertyImages'
import './properties.css'

class Properties extends Component {
    constructor(){
        super()
        this.state = {
            images: []
        }
    }

    componentDidMount() {
        fetch('/services/newpropertyImages.cfc?method=getProperties')
        .then(response => response.json())
        .then(data => {
            const newdata = data.map(function(p) {
                return {original: p.ORIGINAL, thumbnail: p.THUMBNAIL, originalAlt: p.ORIGINALALT, originalTitle: p.ORIGINALTITLE, description: p.DESCRIPTION }
            })
            this.setState({images: newdata})
        })
    }


    render() {
       
        return (
            <div className="pagetransition m-t-4">
                <ImageGallery items={this.state.images} />
            </div>
        )
    }
}

export default Properties