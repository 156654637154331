import React from 'react';

class PriceFord extends React.Component {
    render() {
        return(
            <div>
                <div className="row m-t-8">
                    <div className="col-md-3">
                        <div className="card m-b-4" id="IndPerson">
                            <img src="/images/PriceFordNew.jpg" alt="Price Ford" className="card-img-top"/> 
                            <div className="card-body">
                                <h5 className="m-b-0 text-center card-title">Price D. Ford</h5>
                                <h6 className="m-b-0 text-center">SIOR, CCIM</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <p className="pf-m-t-10">The Founder and Managing Partner of the company, as well as a co-investor in the fund is Price D. Ford Sr., SIOR, CCIM, a regional commercial real estate developer and owner.</p>
                        <p>Mr. Ford started in the commerical real estate business at Trammell Crow in Memphis, Tennessee as a broker/developer. He resigned to pursue his personal investment in Cypress Realty Holdings Company LLC.</p>
                        <p>Mr. Ford has extensive experience in project financing, financial analysis, lease negotiations and documentation, construction management, development, as well as ownership structure.</p>
                        <p>Development expertise includes specialty retail, retail redevelopment, Class A medical office, office flex and restaurant/pad site development.</p>
                        <p>Mr. Ford is a member of the Society of Industrial and Office Realtors, the Commercial Investment Real Estate Institute and Lambda Alpha International Land Economics Society. He was named as one of the 25 most significant real estate producers in metro-Memphis.</p>
                        <p>Mr. Ford devotes a majority of his professional time to the acquisition, development and disposition of commercial real estate for high net worth individuals, as well as institutions in the Southeast United States.</p>
                    </div>
                </div>
            </div>
            )
    }
}

export default PriceFord