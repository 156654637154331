import React, {Component} from 'react';
import Dev1 from '../images/Dev2.jpg';
import Dev2 from '../images/wholefoodsnew.jpg';
import Dev3 from '../images/chilis4.jpg';

class Development extends Component {
    render(){
        return (
            <div className="pagetransition">
                <div className="m-t-4">
                    <div className="card-columns">
                        <div className="card d-none d-md-block" >
                            <img className="card-img-top" src={Dev1} alt="Dev1"/>
                        </div>
                        <div className="card" >
                            <img className="card-img-top" src={Dev2} alt="Dev2"/>
                        </div>
                        <div className="card" >
                            <img className="card-img-top" src={Dev3} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <h2>Property Development Parameters</h2>
                            <p>The fund seeks to pursue development opportunities on a selected basis with an objective toward creating value and owning the respective asset for a relatively long period of time.
                            In regards to retail projects, this would include the following:</p>
                            <ul>
                                <li>Metro MSA population of 500,000 per trade area or larger</li>
                                <li>Major traveled commercial traffic artery</li>
                                <li>Strong traffic counts and resulting submarket demographics</li>
                                <li>Precedence given over markets with superior barriers to entry of competing product</li>
                                <li>Assets that can ultimately be financed with non-recourse debt</li>
                                <li>Assets that can be managed by competent, professional management companies in the respective submarket</li>
                            </ul>	
                            <p>For development opportunities to be considered in regards to industrial product, we prefer the following:</p>	
                            <ul>
                                <li>Large metropolitan MSA's with a population in excess of $1 million</li>
                                <li>The ability to take down 10 acres to 50 acres in a well established industrial trade zone</li>
                                <li>The ability to develop, manage, lease and finance Class A modern bulk warehouse facilities in phases with minimum sizes ranging from 250,000 sq. ft. to 750,000 sq. ft. per building</li>
                                <li>Assets that can be professionally managed</li>
                                <li>Assets that can be financed at completion with non-recourse, long-term financing</li>
                            </ul>	
                        <p>For more specific criteria associated with our development parameters, please contact the 
                            Fund Manager as follows:</p>
                            <p><strong>Price D. Ford, SIOR, CCIM</strong><br/>
                        Cypress Realty Holdings Company<br/>
                        700 Colonial Road, Suite 100<br/>
                        Memphis, TN 38117<br/>
                        Phone: (901) 682-7606<br/>
                        Fax: (901) 682-7992<br/>
                        Email: <a href="mailto:pford@cypressfund.com" target="_blank" rel="noopener noreferrer">pford@cypressfund.com</a></p>
                        <p><strong>Joseph W. Jarratt, SIOR, CCIM</strong><br/>
                        Cypress Realty Holdings Company<br/>
                        700 Colonial Road, Suite 100<br/>
                        Memphis, TN 38117<br/>
                        Phone: (901) 682-7606<br/>
                        Fax: (901) 682-7992<br/>
                        Email: <a href="mailto:joej@cypressfund.com" target="_blank" rel="noopener noreferrer">joej@cypressfund.com</a></p>

                    </div>
                    <div className="col-lg-6">
                    <h2>Property Acquisition Parameters</h2>
                        <p>The fund seeks to invest its capital in primarily investment grade commercial real estate through a 
                            geographic diversification of primarily net leased, retail shopping centers and industrial 
                            projects in the southeastern United States.</p>
                        <p>The company seeks to acquire/develop A+ to C+ assets with an aggregate dollar value per transaction 
                        of $25,000,000 or less per property which allows the company to spread its capital and diversify 
                        its risk to as many projects as possible to achieve maximum diversification.</p>
                        
                        <p>For additional information regarding more detailed information regarding the fund’s acquisition 
                            parameters, please contact the Fund Manager as follows:</p>
                            <p><strong>Price D. Ford, SIOR, CCIM</strong><br/>
                        Cypress Realty Holdings Company<br/>
                        700 Colonial Road, Suite 100<br/>
                        Memphis, TN 38117<br/>
                        Phone: (901) 682-7606<br/>
                        Fax: (901) 682-7992<br/>
                        Email: <a href="mailto:pford@cypressfund.com" target="_blank" rel="noopener noreferrer">pford@cypressfund.com</a>
                        </p>
                        <p><strong>Joseph W. Jarratt, SIOR, CCIM</strong><br/>
                        Cypress Realty Holdings Company<br/>
                        700 Colonial Road, Suite 100<br/>
                        Memphis, TN 38117<br/>
                        Phone: (901) 682-7606<br/>
                        Fax: (901) 682-7992<br/>
                        Email: <a href="mailto:joej@cypressfund.com" target="_blank" rel="noopener noreferrer">joej@cypressfund.com</a>
                        </p>

                    </div>
                </div>
            </div>
        )
    }
}

export default Development