import React from 'react'
import SlideshowImages from './SlideshowImages'
import Slide from './Slide'
import './slides.css'

class HomeSlideshow extends React.Component {
    constructor(){
        super()
        this.state = {
            slideshow: SlideshowImages,
            hidden: true
        }
        
    }

    componentDidMount(){
        setTimeout(function(){
            this.setState({hidden: false})
        }.bind(this), this.props.wait)
    }

   
    render(){
        const slideshowgroup = this.state.slideshow.filter(group => group.groupname === this.props.groupname)
        return(
            <div className="slidecontainer">
                {this.state.hidden ? <div className="slider"><div className="starter"><img src={slideshowgroup[0].url} alt="" className="image"/></div></div> : <Slide key={slideshowgroup.ordernum} slides={slideshowgroup} ></Slide>}
                
            </div>
        )
    }

}

export default HomeSlideshow