import React, {Component} from 'react'
import HomeSlideshow from './Slideshow/HomeSlideshow'



class Home extends Component {
    constructor(){
        super()
        this.state = {}
    }


    render(){
        return(
            <div className="pagetransition">
               
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                            <HomeSlideshow groupname="topleft" wait={0} /> 
                    </div>
                    <div className="col-sm-12 col-md-4">
                            <HomeSlideshow groupname="topcenter" wait={5000} />                
                    </div>
                    <div className="col-sm-12 col-md-4">
                            <HomeSlideshow groupname="topright" wait={3000} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                            <HomeSlideshow groupname="bottomleft" wait={13000} /> 
                    </div>
                    <div className="col-sm-12 col-md-4">
                            <HomeSlideshow groupname="bottomcenter" wait={8000} />              
                    </div>
                    <div className="col-sm-12 col-md-4">
                            <HomeSlideshow groupname="bottomright" wait={10000} /> 
                    </div>
                </div>
                <div className="row m-t-4">
                    <div className="col">
                        <h5>
                        Cypress Realty Holdings Company was formed for the purpose of creating a professionally managed, geographically diverse real estate portfolio that provides its investment partners with high current income, certain tax advantages and long-term capital appreciation for its members by purchasing and/or developing primarily investment grade commercial real estate in the southeastern United States.  
                        </h5>
                    </div>
                </div>
                
            
            </div>
        )
    }
}

export default Home